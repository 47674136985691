define('Magento_Theme/js/mobileapp',['jquery'], function ($) {
    'use strict';
    var shopByMenuAlreadyClicked = false;

    function processMessage(e) {
        var message,
            backLinks;

        if (e.originalEvent.data === 'mobileapp' && !shopByMenuAlreadyClicked) {
            $('.menu-toggle').closest('.row').hide();
            shopByMenuAlreadyClicked = true;
            $('.mob-shopby>a').trigger('click');
            backLinks = $('a[href="#mm-1"]');
            backLinks.attr('href', '#mm-0');
            backLinks.on('click', function () {
                window.parent.postMessage({target: 'shopByCategory', event: 'back'}, '*');
            });
        } else {
            message = e.originalEvent.data;
            if (typeof message !== 'object') {
                try {
                    message = JSON.parse(e.originalEvent.data);
                } catch (err) {
                    message = {target: ''};
                }
            }
            if (message.target !== undefined) {
                if (message.target === 'branchChanger') {
                    $('.store-switch').trigger('click');
                } else if (message.target === 'loginForm') {
                    $('#login-popup').trigger('click');
                } else if (message.target === 'shopByCategory') {
                    $('a[href="#mobile-menu"]').trigger('click');
                }
            }
        }
    }

    $(window).on('message', processMessage);
});
