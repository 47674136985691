/**
 * Copyright © 2016 Magento. All rights reserved.
 * See COPYING.txt for license details.
 */
define('Usesi_Cartoption/js/catalog-add-to-cart',[
    'jquery',
    'Magento_Catalog/js/catalog-add-to-cart',
    'Magento_Customer/js/customer-data',
    'mobilestatus',
    'text!Usesi_Cartoption/template/cart-data.html',
    'mage/template',
    'mage/url',
    'mage/translate'
], function (
    $,
    r,
    customerData,
    mobilestatus,
    popupTemplate,
    mageTemplate,
    url
) {
    $.fn.serializeObject = function () {
        var o = {};
        var a = this.serializeArray();
        $.each(a, function () {
            if (o[this.name] !== undefined) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };
    $.widget('usesi.catalogAddToCart', $.mage.catalogAddToCart, {
        ajaxSubmit: function (form) {
            var self = this;
            var buttons = form.find("button");
            buttons.attr("disabled", true);
            $(self.options.minicartSelector).trigger('contentLoading');
            $.ajax({
                url: form.attr('action'),
                data: form.serialize(),
                type: 'post',
                async: true,
                showLoader: true,
                dataType: 'json',
                beforeSend: function () {
                    if (self.isLoaderEnabled()) {
                        $('body').trigger(self.options.processStart);
                    }
                },
                success: function (res) {
                    $('.loading-mask').hide();
                    if (res.backUrl) {
                        window.location = res.backUrl;
                        return;
                    }
                    if (res.error) {
                        $('#addtocart-validate-modal').find('.add-to-cart-validation-text').html('<div class="alert alert-warning">' + res.exception + '</div>');
                        $('#trigger-addtocartvalidate-modal').trigger('click');
                        buttons.attr("disabled", false);
                        return false;
                    }

                    $('#addtocart-delivery-modal').find('#add-to-cart-popup').html(
                        mageTemplate(
                            popupTemplate,
                            {
                                data: res
                            }
                        )
                    );
                    if (window.location.pathname !== "/checkout/cart/") {
                        $('#trigger-delivery-modal').trigger('click');
                    } else {
                        window.location.reload();
                    }

                    if (self.isLoaderEnabled()) {
                        $('body').trigger(self.options.processStop);
                    }
                    if (res.messages) {
                        $(self.options.messagesSelector).html(res.messages);
                    }
                    if (res.minicart) {
                        $(self.options.minicartSelector).replaceWith(res.minicart);
                        $(self.options.minicartSelector).trigger('contentUpdated');
                    }

                    if (res.product && res.product.statusText) {
                        $(self.options.productStatusSelector)
                            .removeClass('available')
                            .addClass('unavailable')
                            .find('span')
                            .html(res.product.statusText);
                    }
                    buttons.attr("disabled", false);
                }, complete: function () {
                    var sections = ['cart'];
                    customerData.invalidate(sections);
                    customerData.reload(sections, true);
                    /* To notify mobile app about the action */
                    mobilestatus();
                }
            });
        }
    });
    return $.usesi.catalogAddToCart;
});

