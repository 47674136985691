define('Usesi_Cartoption/js/quantity-inc-dec',[
    'jquery',
    'mage/template',
    'text!Usesi_PickupDeliveryData/templates/pickup_delivery.html',
    'text!Usesi_PickupDeliveryData/templates/product/view/pickup_delivery.html',
    'Magento_Customer/js/customer-data',
    'domReady!'
], function($, mageTemplate, pickupDeliveryStickyTmpl, pickupDeliveryTmpl, customerData){

    return function(config) {
        const canShowInventory = config.canShowInventory || false;
        var updateQty = [];
        var branchName = $.cookie('usesiBranch');
        var checkExpiry = function(timestamp) {
            var lifetime = 60;
            var currentTime = Date.now();
            var expiry = timestamp + ( lifetime * 60 * 1000 );
            return currentTime < expiry;
        };
        var updateInv = function(invData,response) {
            var call_toggle_flag=0;
                    if(response.pickup.indexOf("Out")>-1){
                        call_toggle_flag=1;
                    }           
            var pickup_deliver_info = $('.pickup-deleiver-info');
            var delivery_info = $('.product-deliver-stock');
            var pick_info = $('.product-pickup-stock');
            var pickup_delivery_inventory = $('.pickup-delivery-inventory');

            /** Update Sticky Inventory- PDP */
            pickup_deliver_info.html(mageTemplate(pickupDeliveryStickyTmpl, {
                pddata: {
                    pickup: invData.pdpInvData.pickup,
                    call_toggle:call_toggle_flag,
                    pickup_available_status: response.pickup,
                    deliver:invData.pdpInvData.deliver,
                    deliverarea:invData.pdpInvData.deliverarea,
                    delivery_available_status:response.delivery_available_status
                }
            }));
            /** Update Delivery Inventory*/
            delivery_info.html(mageTemplate(pickupDeliveryTmpl, {
                data: {
                    type: 'delivery',
                    deliver:invData.pdpInvData.deliver,
                    delivery_available_status:response.delivery_available_status
                }
            }));
            /** Update Pickup Inventory */
            pick_info.html(mageTemplate(pickupDeliveryTmpl, {
                data: {
                    type: 'pickup',
                    pickup: invData.pdpInvData.pickup,
                    pickup_available_status: response.pickup
                }
            }));
            $('#stock_status').val(invData.pdpInvData.delivery_available_status);
            pickup_deliver_info.trigger('contentUpdated');
            pickup_delivery_inventory.trigger('contentUpdated');
        };

        $('body').on('click', '.inc.button', function () {
            var action = 'prev';
            if ($(this).hasClass('disabled')) {
                return;
            }
            /*inc-dec-qty - PDP*/
            var min_value = $(this).attr('min');
            if ($(".inc-dec-qty").length > 0) {
                var inputQty = $('.inc-dec-qty');
            } else {
                var inputQty = $(this).prev('input');
            }
            var new_value = parseInt(inputQty.val()) + parseInt(min_value),
                    mod_value = parseInt(new_value) % parseInt(min_value);

            if (mod_value != 0) {
                new_value = parseInt(new_value) - parseInt(mod_value);
            }

            if (new_value >= min_value) {
                inputQty.val(new_value);
            }

            if (parseInt(inputQty.val()) > min_value) {
                // remove disabled class from dec button
                if ($(".inc-dec-qty").length > 0) {
                    $('.inc-dec-qty').prev('div').removeClass('disabled');
                } else {
                    $(this).prev('input').prev('div').removeClass('disabled');
                }
            } else {
                if ($(".inc-dec-qty").length > 0) {
                    $('.inc-dec-qty').prev('div').addClass('disabled');
                } else {
                    $(this).prev('input').prev('div').addClass('disabled');
                }
            }
            getPickupDeliveryData(new_value, this, action);

        });

        $('body').on('click', '.dec.button', function () {
            var action = 'next';
            if ($(this).hasClass('disabled')) {
                return;
            }
            /*inc-dec-qty - PDP*/
            var min_value = $(this).attr('min');

            if ($(".inc-dec-qty").length > 0) {
                var inputQty = $('.inc-dec-qty');
            } else {
                var inputQty = $(this).next('input');
            }

            var new_value = parseInt(inputQty.val()) - parseInt(min_value),
                    mod_value = parseInt(new_value) % parseInt(min_value);

            if (mod_value != 0) {
                new_value = parseInt(new_value) - parseInt(mod_value);
            }

            if (new_value < min_value) {
                new_value = min_value;
            }

            inputQty.val(new_value);

            if (new_value <= min_value) {    // decrease button
                if ($(".inc-dec-qty").length > 0) {
                    $('.inc-dec-qty').addClass('disabled');
                } else {
                    $(this).addClass('disabled');
                }
            } else {
                if ($(".inc-dec-qty").length > 0) {
                    $('.inc-dec-qty').removeClass('disabled');
                } else {
                    $(this).removeClass('disabled');
                }
            }

            getPickupDeliveryData(new_value, this, action);
        });

        $('body').on('keyup blur change focus', '.qty-input', function () {
            var min_value = parseInt($(this).attr('min')),
                    qty_value = parseInt($(this).val()),
                    dec_button = $(this).prev('div');

            if (isNaN(qty_value)) {
                dec_button.addClass('disabled');
            } else {
                if (qty_value > min_value) {
                    dec_button.removeClass('disabled');
                } else {
                    dec_button.addClass('disabled');
                }
            }
        });

        // Prevent the Enter key click on QTY field to open Pickup popup in cart page
        $('body').bind("keypress", ".qty-input", function (e) {
            if (e.keyCode == 13) {
                e.preventDefault();
            }
        });

        $('body').on('focusout', '.qty-input', function () {
            /*inc-dec-qty - PDP*/
            var action = 'focusout';
            var min_value = $(this).attr('min'),
                    new_value = $(this).val(),
                    mod_value = new_value % min_value,
                    dec_button = $(this).prev('div');

            if (new_value > 0) {
                if (mod_value != 0) {
                    $('#product-addtocart-button').attr('disabled', 'disabled');
                    $('#product-addtocart-pickup').attr('disabled', 'disabled');
                    new_value = (parseInt(new_value) - parseInt(mod_value)) + parseInt(min_value);
                    if (new_value >= min_value) {
                        dec_button.removeClass('disabled'); // Enabling decrease button
                    } else {
                        new_value = min_value;
                        dec_button.addClass('disabled');    // Disabling decrease button
                    }
                    if ($(".inc-dec-qty").length > 0) {
                        $('.inc-dec-qty').val(new_value);
                    } else {
                        $(this).val(new_value);
                    }
                    $('#product-addtocart-button').removeAttr("disabled");
                    $('#product-addtocart-pickup').removeAttr("disabled");
                } else {
                    if ($(".inc-dec-qty").length > 0) {
                        $('.inc-dec-qty').val(new_value);
                    }
                    $('#product-addtocart-button').removeAttr("disabled");
                    $('#product-addtocart-pickup').removeAttr("disabled");
                }
            } else {
                if ($(".inc-dec-qty").length > 0) {
                    $('.inc-dec-qty').val(min_value);
                } else {
                    $(this).val(min_value);
                }

            }
            getPickupDeliveryData(new_value, this, action);
        });

        function getPickupDeliveryData(qty, element_clicked, action)
        {
            if (!canShowInventory) {
                return;
            }
            if (parseInt($(element_clicked).attr('invupdate')) == 0) {
                return false;
            }
            action = action || null;
            var _productID = $('.pickup-delivery-product-id').val();
            if ($('.pickup-delivery-product-id').length < 1) {
                _productID = parseInt($(element_clicked).attr('magentopid'));
            }

            if (action == 'prev') {
                if ($(element_clicked).prev('input').attr("data-productid")) {
                    _productID = $(element_clicked).prev('input').attr("data-productid");
                }
            } else if (action == 'next') {
                if ($(element_clicked).next('input').attr("data-productid")) {
                    _productID = $(element_clicked).next('input').attr("data-productid");
                }
            } else if (action == 'focusout'){
                if ($(element_clicked).attr("data-productid")) {
                    _productID = $(element_clicked).attr("data-productid");
                }
            }
            $('.invloader').removeClass('hidden');
            var url = window.location.protocol + '//' + window.location.hostname + '/pddata/index';
            $.ajax({
                url: url,
                type: 'GET',
                data: {
                    pdata: _productID,
                    rqty: qty
                },
                success: function (response) {
                    if (typeof (response) == 'string') {
                        response = jQuery.parseJSON(response);
                    }
                    var productSKU = '';
                    var flag = $('body').hasClass('catalog-product-view');
                    var invLocal = JSON.parse(window.getLocalStorage('pdpInvData', true));
                    if (invLocal == null) {
                        invLocal = {};
                    }
                    var ownBranchObj = invLocal[branchName] || {};
                    if (flag) {
                        if ($('#productsku').length > 0) {
                            productSKU = $('#productsku').val();
                        }
                        if (
                            invLocal.hasOwnProperty(branchName) &&
                            ownBranchObj.hasOwnProperty(productSKU) &&
                            checkExpiry(invLocal[branchName][productSKU].timestamp)
                        ) {
                            var product = invLocal[branchName][productSKU];
                            updateInv(product.invData,response);
                            $('.invloader').addClass('hidden');
                        }
                    } else {
                        $('.pickup-delivery-data').find('#product_pickupdata').show();
                        $('.product-pickup-data').html(response.pickup);
                        if (response.pickup == false) {
                            $('.product-pickup-data').html("Not available for immediate pickup");
                        }
                        $('#stock_status').val(response.delivery);
                        $('.invloader').addClass('hidden');        
                    }
                }
            });
        }

        var qtyUpdate = [];
        function lpGetPickupDeliveryData(qty, element_clicked) {
            if (!canShowInventory) {
                return;
            }
            if (parseInt($(element_clicked).attr('invupdate')) == 0) {
                return false;	
            }
            var parentProductItem = $(element_clicked).closest('.product-item');
            var placeCode = false;
            var itemId = "";
            var productID = parseInt($(element_clicked).attr('magentopid'));
            var productSKU = parseInt($(element_clicked).attr('magentosku'));
            var updateCartItem = 0;
            var itemUniqueId = productID;
            if (window.isCartPage) {
                placeCode = $(element_clicked).attr('placeid');
                itemId = $(element_clicked).attr('data-cart-item');
                updateCartItem = 1;
                itemUniqueId = itemId;
            }
            parentProductItem.find('.invloader-' + itemUniqueId).removeClass('hidden');
            if (qtyUpdate.length !== 0 && qtyUpdate[itemUniqueId] && qtyUpdate[itemUniqueId].readyState !== 4) {
                qtyUpdate[itemUniqueId].abort();
            }
            qtyUpdate[itemUniqueId] = $.ajax({
                url: baseSiteUrl + 'pddata/index',
                type: 'GET',
                data: {
                    pdata: productID,
                    rqty: qty,
                    placecode: placeCode,
                    updateitem: updateCartItem,
                    itemId: itemId,
                    formatAvailability: $(element_clicked).data('page') == 'plp'
                },
                success: function (response) {
                    Array.prototype.remove = function(el) {
                        return this.splice(this.indexOf(el), 1);
                    }
                    qtyUpdate.remove(itemUniqueId);
                    if (typeof (response) == 'string') {
                        response = jQuery.parseJSON(response);
                    }
                    if ($(element_clicked).data('page') == 'plp') {
                        var $container = $(element_clicked).closest('div.product-item');
                        $container.find('.deliver-availability').html(response.delivery_available_status);
                        $container.find('.pickup-availability').html(response.pickup);
                        return;
                    }
                    if (window.isCartPage) {
                        /** update Min cart */
                        updateCartSummary(response);
                        /** Update Inventory */
                        updateCartInventory(response, itemId, parentProductItem);
                        /** Stock Warning Message */
                        stockwarning(itemId,response.stock);
                    }

                if($(element_clicked).hasClass('shoppinglist')){
                    var deliveryInv = parentProductItem.find('.product-delivery-data');
                    var pickupInv = parentProductItem.find('.product-stock-data');
                    if(response.stock == 0){
                            pickupInv.addClass('hidden');
                            deliveryInv.removeClass('delivery-shipping');
                            deliveryInv.addClass('delivery-phone');
                            deliveryInv.html(response.delivery_available_status);
                    } else {
                            pickupInv.removeClass('hidden');
                            deliveryInv.addClass('delivery-shipping');
                            deliveryInv.removeClass('delivery-phone');
                            deliveryInv.html(response.delivery);
                    }
                }
                if ($(element_clicked).hasClass('yourcatalogQty')) {
                    parentProductItem.find('.deliverOn').removeClass('text-danger');
                    parentProductItem.find('.pickUpOn').removeClass('text-danger');
                    if (response.stock == 0) {
                        parentProductItem.find('.deliverOn').addClass('text-danger');
                        parentProductItem.find('.pickUpOn').addClass('text-danger');
                    }
                    parentProductItem.find('.deliverOn').html(response.delivery_available_status);
                    parentProductItem.find('.pickUpOn').html(response.pickup);
                }

                $('#stock_status-'+productSKU).val(response.delivery);
                $('#stock_status').val(response.delivery);
                $('.invloader-' + itemUniqueId).addClass('hidden');
            }
            });
        }

        function updateCartInventory(response, itemId, parentProductItem)
        {
            /** Price Update */
            if(response.total){
                $('#priceupdate-' + itemId + ' .cart-price').html(response.total);
            }
            parentProductItem.find('#product_pickupdata').show();
            parentProductItem.find('.topickup').show();
            /** Update Delivery Inventory - Cart Page*/
            parentProductItem.find('.product-deliver-stock').html(mageTemplate(pickupDeliveryTmpl, {
                data: {
                    type: 'delivery',
                    deliver:parentProductItem.find('.product-deliver-stock').attr('data-onhand'),
                    delivery_available_status:response.delivery_available_status
                }
            }));
            /** Update Pickup Inventory - Cart Page */
            parentProductItem.find('.product-pickup-stock').html(mageTemplate(pickupDeliveryTmpl, {
                data: {
                    type: 'pickup',
                    pickup: parentProductItem.find('.product-pickup-stock').attr('data-onhand'),
                    pickup_available_status: response.pickup
                }
            }));
        }

        function updateCartSummary(response)
        {
            /** Reload Cart Summary */
            if(window.reloadSummary){
                window.reloadSummary();
            }
            /** Reload Cart */
            var sections = ['cart'];
            customerData.invalidate(sections);
            customerData.reload(sections, true);
        }

        function stockwarning(itemId,stock)
        {
            var itemwarning = $('#shopping-cart-table').find('.items-list[data-itemid=' + itemId + '] .item-warning');
            if (stock == 1) {
                itemwarning.addClass('hidden');
            } else {
                itemwarning.removeClass('hidden');
            }

            var cartwarning = $('#shopping-cart-table').find('.stock-warning-top');
            if($('.stock-warning.item-warning:visible').length >= 1){
                cartwarning.removeClass('hidden');
            } else {
                cartwarning.addClass('hidden');
            }
        }

        $('body').on('click', '.lp-inc.button', function () {
            if ($(this).hasClass('disabled')) {
                return;
            }

            var min_value = $(this).attr('min'),
                    inputQty = $(this).prev('input'),
                    new_value = parseInt(inputQty.val()) + parseInt(min_value),
                    mod_value = parseInt(new_value) % parseInt(min_value);
            if (mod_value != 0) {
                new_value = parseInt(new_value) - parseInt(mod_value);
            }

            if (new_value >= min_value) {
                inputQty.val(new_value);
            }

            if (parseInt(inputQty.val()) > min_value) {
                /* remove disabled class from dec button */
                $(this).prev('input').prev('div').removeClass('disabled');
            } else if($(this).hasClass('shoppinglist') && parseInt(inputQty.val()) == min_value) {
                $(this).prev('input').prev('div').removeClass('disabled');
            } else {
                $(this).prev('input').prev('div').addClass('disabled');
            }
            $itemUniqueId =  parseInt($(this).attr('magentopid'));
            if (window.isCartPage) {
                $itemUniqueId = $(this).attr('data-cart-item');
            }
            if (updateQty[$itemUniqueId]) {
                clearTimeout(updateQty[$itemUniqueId]);
            }
            updateQty[$itemUniqueId] = setTimeout(lpGetPickupDeliveryData.bind(null, new_value, this), 1000);
        });

        $('body').on('click', '.lp-inc-new.button', function () {
            if ($(this).hasClass('disabled')) {
                return;
            }

            var min_value = $(this).attr('min'),
                    inputQty = $(this).prev('span').prev('input'),
                    new_value = parseInt(inputQty.val()) + parseInt(min_value),
                    mod_value = parseInt(new_value) % parseInt(min_value);
            if (mod_value != 0) {
                new_value = parseInt(new_value) - parseInt(mod_value);
            }

            if (new_value >= min_value) {
                inputQty.val(new_value);
            }

            if (parseInt(inputQty.val()) > min_value) {
                /* remove disabled class from dec button */
                $(this).prev('span').prev('input').prev('div').removeClass('disabled');
            } else {
                $(this).prev('span').prev('input').prev('div').addClass('disabled');
            }
            lpGetPickupDeliveryData(new_value, this);
        });

        $('body').on('click', '.lp-dec.button', function () {
            var min_value = $(this).attr('min'),
                    inputQty = $(this).next('input'),
                    new_value = parseInt(inputQty.val()) - parseInt(min_value),
                    mod_value = parseInt(new_value) % parseInt(min_value);
            if($(this).hasClass('shoppinglist')){
                if(new_value <= 0){
                    inputQty.val('0');
                    $itemUniqueId =  parseInt($(this).attr('magentopid'));
                    if (window.isCartPage) {
                        $itemUniqueId = $(this).attr('data-cart-item');
                    }
                    if (updateQty[$itemUniqueId]) {
                        clearTimeout(updateQty[$itemUniqueId]);
                    }
                    updateQty[$itemUniqueId] = setTimeout(lpGetPickupDeliveryData.bind(null, min_value, this), 1000);
                    $(this).addClass('disabled');
                    return;
                }
            }
            if ($(this).hasClass('disabled')) {
                return;
            }
            if (mod_value != 0) {
                new_value = parseInt(new_value) - parseInt(mod_value);
            }

            if (new_value < min_value) {
                new_value = min_value;
            }

            inputQty.val(new_value);

            if (new_value <= min_value && !$(this).hasClass('shoppinglist')) {
                $(this).addClass('disabled');
            } else {
                $(this).removeClass('disabled');
            }
            $itemUniqueId =  parseInt($(this).attr('magentopid'));
            if (window.isCartPage) {
                $itemUniqueId = $(this).attr('data-cart-item');
            }
            if (updateQty[$itemUniqueId]) {
                clearTimeout(updateQty[$itemUniqueId]);
            }
            updateQty[$itemUniqueId] = setTimeout(lpGetPickupDeliveryData.bind(null, new_value, this), 1000);
        });

        /* Prevent the Enter key click on QTY field to open Pickup popup in cart page */
        $('body').bind('keypress', '.lp-qty-input', function (e) {
            if (e.keyCode == 13) {
                e.preventDefault();
            }
        });

        function updateDeliverData(self, deliverValue) {
            var deliverData = deliverValue.toLowerCase();
            var ele = $(self);
            if (deliverData.substr(0, 4) == 'call') {
                var data = '<a href="/contact" target="_blank">' + deliverValue + '</a>';
                ele.removeClass('delivery-shipping').addClass('delivery-phone').html(data);
            } else {
                ele.removeClass('delivery-phone').addClass('delivery-shipping').html(deliverValue);
            }
        }
        
        $('body').on('keyup', '.lp-qty-input', function () {
            var min_value = ($(this).hasClass('shoppinglist')) ? $(this).attr('data-min') : $(this).attr('min');
            var qty_value = parseInt($(this).val()),
                    dec_button = $(this).prev('div');
            if (isNaN(qty_value)) {
                dec_button.addClass('disabled');
                return false;
            }
            if($(this).hasClass('shoppinglist')){
                if(qty_value <= 0){
                    $(this).val('0');
                    lpGetPickupDeliveryData(min_value, this);
                    dec_button.addClass('disabled');
                    return;
                }else {
                    dec_button.removeClass('disabled');
                    return;
                }
            }
            if (qty_value > min_value) {
                dec_button.removeClass('disabled');
            } else {
                dec_button.addClass('disabled');
            }
        });

        $('body').on('focusout', '.lp-qty-input', function () {
            var min_value = ($(this).hasClass('shoppinglist')) ? $(this).attr('data-min') : $(this).attr('min');
            var new_value = $(this).val(),
                    mod_value = new_value % min_value,
                    dec_button = $(this).prev('div');
            if($(this).hasClass('shoppinglist')){
                if(new_value == 0){
                    $(this).val('0');
                    lpGetPickupDeliveryData(min_value, this);
                    return false;
                }
            }
            if (new_value > 0) {
                if (mod_value != 0) {
                    $('#product-addtocart-button').attr('disabled', 'disabled');
                    $('#product-addtocart-pickup').attr('disabled', 'disabled');
                    new_value = (parseInt(new_value) - parseInt(mod_value)) + parseInt(min_value);
                    if (new_value >= min_value) {
                        dec_button.removeClass('disabled'); /* Enabling decrease button */
                    } else {
                        new_value = min_value;
                    }
                    $(this).val(new_value);
                    lpGetPickupDeliveryData(new_value, this);
                    $('#product-addtocart-button').removeAttr("disabled");
                    $('#product-addtocart-pickup').removeAttr("disabled");
                    return false;
                } else {
                    dec_button.removeClass('disabled');
                    $('#product-addtocart-button').removeAttr("disabled");
                    $('#product-addtocart-pickup').removeAttr("disabled");
                }
                lpGetPickupDeliveryData(new_value, this);
            } else {
                ($(this).hasClass('shoppinglist')) ? $(this).val('0') : $(this).val(min_value);
                lpGetPickupDeliveryData(min_value, this);
                dec_button.addClass('disabled');
                return false;
            }
        });
    };
});
