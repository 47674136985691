define('Magento_Theme/js/addressbook',[
  'jquery',
  'uiComponent',
  'ko',
  'list'
], function ($, Component, ko) {
        return Component.extend({
            addressBookModal: 1,
            setShiptoAccount:1,
            addressListHTML: ko.observable(1),
            modalAddresses: ko.observableArray([]),
            addressSearch: 1,
            addressSearchInput: ko.observable(""),
            isDeliver:1,
            addressId:1,

            initialize: function () {
                var self = this;
                this._super();
                if (document.readyState == 'complete') {
                    self.getEclipseAPI(self);
                } else {
                    window.addEventListener("load", function() {
                        self.getEclipseAPI(self);
                    });
                }
            },


            /**
             * Gets the addresses  list from eclipse
             */
            getEclipseAPI: function (self) {
                var url = window.location.protocol+'//'+window.location.hostname + "/addresscheckout";
                /* For B2B change address button from Myaccount header link */
                var isB2bAddr = $('.js-b2baddr').length;
                (isB2bAddr) ? $('.js-b2baddr').addClass('hidden') : '' ;
                $.ajax({
                    url: url,
                    type:'POST',
                    data:{
                      canusesession:'yes'
                    },
                    success:function(response){
                        switch(response.status) {
                            case 'success':
                                $('.error-addrlookup').addClass('hidden');
                                if (response.addresses.length > 1) {
                                    self.modalAddresses(response.addresses);
                                    var displayChangeBtn = setInterval(function () {
                                        var isB2bAddr = $('.js-b2baddr').length;
                                        if (isB2bAddr) {
                                            $('.js-b2baddr').removeClass('hidden');
                                            clearInterval(displayChangeBtn);
                                        }
                                    }, 500);
                                }
                                setTimeout(function () {
                                    self.setPagination();
                                }, 3000);
                                break;
                            case 'account-inactive':
                                break;
                            case 'fetch-failed':
                            default:
                                $('.error-addrlookup').html('Couldn\'t fetch addresses from your address book').removeClass('hidden');
                                $('#address-search-list,.input-searchlookup').hide();
                        }
                    }
                });
            },

            setPagination: function () {
                new List('address-search-list', {
                    valueNames:  ['addressindex', 'addressline1', 'addressline2', 'city', 'state', 'zip'],
                    page: 3,
                    pagination: true
                });
            },

            saveEclipseAddress: function(c, event){
                var element = event.target;
                var cid = element.getAttribute('cid');
                var url = window.location.protocol+'//'+window.location.hostname + "/addresscheckout/shipto?cid=" + cid;
                var address_data = {
                              home_branch:element.getAttribute('home_branch'),
                              price_branch_override:element.getAttribute('price_branch_override'),
                              name:element.getAttribute('name'),
                              ad1:element.getAttribute('ad1'),
                              ad2:element.getAttribute('ad2'),
                              city:element.getAttribute('city'),
                              region_code:element.getAttribute('region_code'),
                              zip:element.getAttribute('zip'),
                              country:element.getAttribute('country'),
                              telephone:element.getAttribute('telephone'),
                              ship_override:element.getAttribute('ship_override'),
                              po_number:element.getAttribute('po_number'),
                              release_number:element.getAttribute('release_number'),
                              po_required:element.getAttribute('po_required'),
                            };
                $.ajax({
                    url: url,
                    type:'POST',
                    showLoader:true,
                    data:{
                      cid:cid,
                      data:address_data
                    },
                    success:function(response){
                      if (parseInt(response) == 200) {
                          $('#trigger-addressbook-modal').trigger('click'); // Closing the popup modal when request is successful
                          $('#trigger-addtocartvalidate-modal').trigger('click'); // Trigger the popup to show price update information
                          $('#addtocart-validate-modal').find('.modal-header').addClass('hidden');
                          $('#addtocart-validate-modal').find('.add-to-cart-validation-text').html("<i class=\"fa fa-spinner fa-spin\"></i>&nbsp;Updating product prices for the selected account.");

                          setTimeout(function() {
                              window.location.reload();    /* Refresh the page if shipToId changes */
                          }, 3000);

                          /*
                          $('.dropdown #dropdown-customer-addressindex').html(address_data['name']);
                          if (typeof address_data.ad1 != 'undefined' && address_data.ad1.length > 0) {
                                  address_data.ad1 += '<br>';
                          } else {
                                  address_data.ad1 = '';
                          }
                          if (typeof address_data.ad2 != 'undefined' && address_data.ad2.length > 0) {
                                  address_data.ad2 += '<br>';
                          } else {
                                  address_data.ad2 = '';
                          }

                          $('.dropdown #dropdown-customer-address').html(address_data['ad1'] + address_data['ad2'] + address_data['city'] + ", " + address_data['region_code'] + " " + address_data['zip']);
                          $('.dropdown #customer_address_id').html(cid);
                          $('#dropdown-trigger-myaccount').dropdown('toggle');
                          location.reload();  */
                      }
                      if(parseInt(response) == 500){
                          $('.message').addClass("message-error error");
                          $('.messages').show();
                          setTimeout(function () {
                              $('.message').removeClass("message-error error");
                              $('.messages').hide();
                          }, 5000);
                      }
                    }
                });
            }

        });
    }
);

