define('Usesi_Customer/js/action/mobile-status',[
    'jquery','Usesi_PushNotifier/js/fcmToken'
], function ($,fcmToken) {
    'use strict';
    return function () {
        var mobileParams = window.location.href.indexOf("mobileapp=1");
        var mobileUseragent = navigator.userAgent.indexOf("HTTP_MOBILEAPP");
        if (mobileUseragent !== -1 || mobileParams !== -1) {
            /*change href for webview only */
            if ($('.pdp-share').length > 0) {
                $('.pdp-share').attr('href', 'share://inapp');
                $('.share-links.popover').remove();
            }
            if ($('.action.print').length > 0) {
                var printLink = $('<a>').attr('href', 'print://inapp')
                        .html("<i class = 'fa fa-print'></i> Print This Page");
                $('.action.print').after(printLink);
                $('.action.print').remove();
            }
            /* End */

            /* Mobile Status Handler Call */
            $.ajax({
                url: window.BASE_URL + 'customer/index/status',
                type: 'GET',
                success: function (response) {
                    var hash = 0;
                    var char;
                    var str = JSON.stringify(response);
                    if (str.length != 0) {
                        for (var i = 0; i < str.length; i++) {
                            char = str.charCodeAt(i);
                            hash = ((hash<<5)-hash)+char;
                            hash = hash & hash;
                        }
                    }
                    response.token = response.android_token;
                    var data = { 'data': response, 'hashKey': hash};
                    if (window.hasOwnProperty('webkit')) {
                        response.token = response.ios_token;
                        var data =  {'data': response};
                        window.webkit.messageHandlers.StatusHandler.postMessage(data);
                    } else if(window.hasOwnProperty('StatusHandler')) {
                        window.StatusHandler.postMessage(JSON.stringify(data));
                    }                   
                }
            });
            /* End */
        }
    }
});

