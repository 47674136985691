/**
 * Copyright © 2016 Magento. All rights reserved.
 * See COPYING.txt for license details.
 */
/*jshint browser:true jquery:true*/
/*global alert*/
define(
    'Usesi_Splitcart/js/view/checkout/summary/grand-total',[
        'Magento_Checkout/js/view/summary/abstract-total',
        'Magento_Checkout/js/model/quote',
        'Magento_Catalog/js/price-utils',
        'Magento_Checkout/js/model/totals'
    ],
    function (Component, quote, priceUtils, totals) {
        "use strict";
        return Component.extend(
            {
                defaults: {
                    isFullTaxSummaryDisplayed: window.checkoutConfig.isFullTaxSummaryDisplayed || false,
                    template: 'Magento_Tax/checkout/summary/grand-total'
                },
                totals: quote.getTotals(),
                isTaxDisplayedInGrandTotal: window.checkoutConfig.includeTaxInGrandTotal || false,
                isDisplayed: function () {
                    return this.isFullMode();
                },
                getValue: function () {
                    var price = 0;
                    if (this.totals()) {
                        price = totals.getSegment('grand_total').value;
                        price = parseFloat(price) + 0.0001;   /*To avoid 1 cent difference in grand total*/
                    }
                    price = parseFloat(price);
                    return this.getFormattedPrice(price.toFixed(2));
                },
                getCartValue: function () {
                    var price = 0;
                    if (this.totals()) {
                        price = totals.getSegment('grand_total').value;
                    }
                    var taxTotal=totals.getSegment('tax').value;
                    var shippingTotal=totals.getSegment('shipping').value;
                    /* Added 0.0001 with price to avoid 1 cent difference in grand total */
                    var priceWoTax = parseFloat(price) - (parseFloat(taxTotal) + parseFloat(shippingTotal)) + 0.0001;
                    priceWoTax = parseFloat(priceWoTax);
                    return this.getFormattedPrice(priceWoTax.toFixed(2));
                },
                getBaseValue: function () {
                    var price = 0;
                    if (this.totals()) {
                        price = this.totals().base_grand_total;
                        price = parseFloat(price) + 0.0001;   /*To avoid 1 cent difference in grand total*/
                    }
                    return priceUtils.formatPrice(price, quote.getBasePriceFormat());
                },
                getGrandTotalExclTax: function () {
                    var price = 0;
                    var totals = this.totals();
                    if (!totals) {
                        return 0;
                    }
                    price = parseFloat(totals.grand_total);
                    price = parseFloat(price) + 0.0001;   /*To avoid 1 cent difference in grand total*/
                    return this.getFormattedPrice(price.toFixed(2));
                },
                isBaseGrandTotalDisplayNeeded: function () {
                    var totals = this.totals();
                    if (!totals) {
                        return false;
                    }
                    return totals.base_currency_code != totals.quote_currency_code;
                }
            }
        );
    }
);

