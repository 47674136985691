define('Usesi_Wishlists/js/shoppinglist-require',['jquery',
    'mage/translate'
], function ($, $t) {
    return function () {
        var action;
        var data = [];
        var qty;
        $('body').on('click', '.addtolist, .towishlist', function (e) {
            if ($(this).data('post')) {
                e.preventDefault();
                e.stopPropagation();

                if ($('body.checkout-onepage-success').length > 0) {
                    var closestRow = $(this).closest('.items-list');
                    qty = parseInt(closestRow.find('input.qty').val());
                } else {
                    var closestRow = $(this).closest('.product-item').length > 0 ?
                            $(this).closest('.product-item') :
                            $(this).closest('.detail-wrap');
                    qty = parseInt(closestRow.find('.input-text.qty').val());
                }
                
                data = $(this).data('post').data;

                if(!isNaN(qty)){
                    data['qty'] = qty;
                }

                action = $(this).data('post').action;
                $('#shoppinglistModal').btsmodal('show');
            }
        });

        $('#shoppinglistModal').on("shown.bs.modal", function () {
            if ($('.mshoppinglist_row #new').is(":checked")) {
                $('#mshoppinglist_new').removeAttr('disabled');
                $('#mshoppinglist_new').trigger('focus');
            }
        });

        $('#shoppinglistModal').on("keypress", function(e) {
            if (e.which == 13) {
                $('#add-to-shoppinglist').trigger('click');
            }
            if (e.which == 27) {
                $('#shoppinglistModal').btsmodal('hide');
            }
        });

        $('#mshoppinglist-create, #mshoppinglist_name').on("keypress", function(e) {
            var button = e.target.id == 'mshoppinglist-create' ? '#mshoppinglist_create' : '#mshoppinglist_rename';
            if (e.which == 13) {
                $(button).trigger('click');
            }
        });

        $('body').on('click', '.remove', function (e) {
            var url = $(this).data('shoppinglist-delete').deleteLink;
            var redirect = $(this).data('shoppinglist-delete').redirectLink;
            var postData = {};
            postData["form_key" ] = $.cookie('form_key');

            $("#deleteListConfirm").btsmodal('show');
            $('#deletelist').on('click', function (e) {
                $.ajax({
                    url: url,
                    type: 'POST',
                    data: postData
                }).always(function (data) {
                        $("#deleteListConfirm").btsmodal('hide');
                        window.location.href = redirect;
                });
            });
        });

        $('#mshoppinglist_rename').on('click', function (e) {
            var name = $('#mshoppinglist_name').val();
            if (name.length > 30) {
                e.preventDefault();
                $('.form-validation').html($t('name length validation'));
                $('.form-validation').removeClass('hidden');
                return;
            }
            if (name === '') {
                e.preventDefault();
                $('.form-validation').html($t('enter name validation'));
                $('.form-validation').removeClass('hidden');
                return;
            }
        });

        $('#mshoppinglist_create').on('click', function (e) {
            var name = $('#mshoppinglist-create').val();
            if (name.length > 30) {
                e.preventDefault();
                $('.create-form-validation').html($t('name length validation'));
                $('.create-form-validation').removeClass('hidden');
                return;
            }
            if (name === '') {
                e.preventDefault();
                $('.create-form-validation').html($t('enter name validation'));
                $('.create-form-validation').removeClass('hidden');
                return;
            }
        });

        function addValidation(errorMessage) {
            $('.validation-msg').html($t(errorMessage));
            $('.validation-msg').show();
        }
        
        $('#add-to-shoppinglist').on('click', function (e) {
            e.preventDefault();
            var shoppingListName = $('#shoppingList').find('input[name="shoppinglist_name"]:checked').val();
            var newName = $('#shoppingList').find('input[name="newlistname"]').val();
            data['shoppinglist_name'] = newName;
            if (shoppingListName !== '') {
                data['wishlist_name'] = shoppingListName;
                data['shoppinglist_name'] = "";
            }

            if (shoppingListName === '' && newName === '') {
                addValidation('enter name validation');
                return;
            }
            
            var specialCharValid = /^[\w\ ]+$/i.test(newName);
            if (!specialCharValid && newName !== '' && shoppingListName === '') {
                addValidation($t('wishlist invalid characters error'));
                return;
            }
            
            if (newName.length > 30) {
                addValidation('name length validation');
                return;
            }

            var existingWishlistNameError = $t('wishlist invalid characters error - existing').replace('%1',window.location.origin).replace('%2',encodeURIComponent(shoppingListName));
            var chkExistingListName = /^[\w\ ]+$/i.test(shoppingListName);
            if (!chkExistingListName && shoppingListName != '') {
                $('#shoppinglistModal').btsmodal('hide');
                $('#messageModal .modal-body').html("<div class='alert alert-danger'>" + existingWishlistNameError + "</div>");
                $('#messageModal').btsmodal('show');
                return;
            }

            if ($.cookie('form_key')) {
                data['form_key'] = $.cookie('form_key');
            } else {
                data['form_key'] = $("input[name=form_key]").val();
            }
            
            $.ajax({
                url: action,
                type: 'POST',
                data: data,
                beforeSend: function () {
                    $('#shoppinglistModal .loading-mask').show();
                }
            }).always(function (data) {
                    $('#shoppinglistModal .loading-mask').hide();
                    $('#shoppinglistModal').btsmodal('hide');
                    if (typeof(data.wishlist_name) !== 'undefined' && data.wishlist_name !== null) {
                        $('#messageModal .modal-body').html('<div class="alert alert-success">Product "' + data.product_name + '" added to ' + data.shoppinglist_name + ' shopping list </div>');
                        $('#messageModal').btsmodal('show');
                    } else {
                        var btn = '';
                        if (typeof (data.refresh) !== 'undefined') {
                            btn = "<div class='text-right'><a class='btn btn-primary mt-10 btn-sm' href='javascript:location.reload()'>Refresh</a></div>";
                        }
                        
                        if (data.hasOwnProperty('isWishlistNew')) {
                            btn = data.isWishlistNew
                                ? $t('wishlist invalid characters error')
                                : existingWishlistNameError;
                        }
                        $('#messageModal .modal-body').html("<div class='alert alert-danger'>" + data.message + btn + "</div>");
                        $('#messageModal').btsmodal('show');
                    }
                });
        });

        /**Enable loader*/
        $('.mshoppinglist-tab a').on('click', function () {
            $('body').trigger('processStart');
        });

        var mobileViewWidth = 767;
        var tabContainer = $('#mshoppinglist-tab-container');
        if (tabContainer) {
            tabResize();
            if (window.location.pathname.indexOf('wishlist_name') !== -1) {
                $('html, body').animate({
                    scrollTop: $('#wishlist-view-form').offset().top - 100
                }, 500);
            }
        }
        $(window).on('resize', function () {
            if (tabContainer) {
                tabResize();
            }
        });
        function tabResize() {

            if (tabContainer.width() > mobileViewWidth) {
                if (tabContainer.hasClass('mshoppinglist-mobile')) {
                    tabContainer.removeClass('mshoppinglist-mobile');
                }
                $([0, 50, 550, 850]).each(function (index, delay) {
                    setTimeout(function () {
                        updateTabArrows();
                    }, 1000);
                });
            } else {
                if (!tabContainer.hasClass('mshoppinglist-mobile')) {
                    tabContainer.addClass('mshoppinglist-mobile');
                }
            }
        }

        function updateTabArrows() {
            if ($('#mshoppinglist-tabs-inner').length) {
                var inner = $('#mshoppinglist-tabs-inner')[0], outer = $('#mshoppinglist-tabs-outer')[0], tabs = $('#mshoppinglist-tabs');

                if (inner.offsetWidth + inner.offsetLeft > outer.offsetWidth)
                    tabs.addClass('with-right-arrow');
                else
                    tabs.removeClass('with-right-arrow');
                if (inner.offsetWidth + inner.offsetLeft < outer.offsetWidth && inner.offsetLeft < 0)
                    $(inner).css({left: outer.offsetWidth - inner.offsetWidth + 'px'});
                if (inner.offsetLeft < 0)
                    tabs.addClass('with-left-arrow');
                else
                    tabs.removeClass('with-left-arrow');
                if (inner.offsetLeft > 0)
                    $(inner).css({left: '0px'});
            }
        }

        $('#mshoppinglist-left-arrow').on('click', function (ev) {
            var inner = $('#mshoppinglist-tabs-inner'), tabs = $('.mshoppinglist-tab'), left = 0;
            for (var i = tabs.length - 1; i > 1; i--)
                if (tabs[i].offsetLeft + inner[0].offsetLeft <= 0)
                    break;
            if (i > 1)
                left = -(tabs[i - 2].offsetLeft + tabs[i - 2].offsetWidth) - 20;
            inner.css({left: left + 'px'});
            $([0, 50, 550, 850]).each(function (index, delay) {
                setTimeout(function () {
                    updateTabArrows()
                }, delay);
            });
        });
        $('#mshoppinglist-right-arrow').on('click', function (ev) {
            var inner = $('#mshoppinglist-tabs-inner'), tabs = $('.mshoppinglist-tab');
            for (var i = tabs.length - 1; i > 0; i--)
                if (tabs[i].offsetLeft + inner[0].offsetLeft <= 0)
                    break;
            inner.css({left: -(tabs[i].offsetLeft + tabs[i].offsetWidth) - 20 + 'px'});
            $([0, 50, 550, 850]).each(function (index, delay) {
                setTimeout(function () {
                    updateTabArrows()
                }, delay);
            });
        });

    };
});

