/**
 * Copyright © 2016 Magento. All rights reserved.
 * See COPYING.txt for license details.
 */
/*jshint browser:true jquery:true*/
/*global alert*/
define(
    'Usesi_Cartoption/js/lineComment',['jquery','Magento_Checkout/js/model/quote',"Magento_Customer/js/customer-data"],
    function ($,quote,customerData) {
        "use strict";
        var $body = $('body');
        $(document).ready(function() {
            $('#shopping-cart-table textarea').each(function() {
                if ($(this).val().length > 0) {
                    var note = $(this).closest('.addnote-strip').find('label');
                    note.trigger('click');
                    note.next('.collapse').addClass('in');
                }
            });

            $body.on('click', '.update-comment', function() {
                var wireCutWrapper = $(this).closest('.comment-container').find('.wireCutWrapper');
                wireCutWrapper.find('.update-comment').removeClass('hidden');
                saveWireCuts(wireCutWrapper);
                var clsStrip = $(this).closest('.addnote-strip');
                var rawComment = clsStrip.find('textarea').val();
                var comment = encodeURIComponent(rawComment);
                var id = $(this).data('id');
                var quoteId = $('#quoteId').val();
                if (typeof quoteId == 'undefined') {
                    quoteId = $('#quoteId-' + id).val();
                }
                var homeUrl = window.location.origin;
                var dataString = 'comment=' + comment + '&id=' + id + '&qid=' + quoteId;
                $.ajax({
                    url: homeUrl + '/cartoption/index/addcomment',
                    type: 'POST',
                    showLoader: true,
                    data: dataString,
                    success: function (data) {
                        $('#update-comment-' + data).addClass('hidden');
                        $('#close-comment-' + data).addClass('hidden');
                        $('#product-item-comment-' + data).attr('data-comment', rawComment);
                        if (rawComment.length <= 0) {
                            $('#label-' + data).trigger('click');
                            $('#comment-container-' + data).removeClass('in');
                            if ($('#addnotebtn-' + data).length > 0) {
                                $('#addnotebtnlabel' + data).removeClass('hidden');
                                $('#container-' + data).addClass('hidden');
                                $('#label-' + data).trigger('click');
                                $('#comment-container-' + data).addClass('in');
                            }
                        }
                        processWireCuts(wireCutWrapper);
                        var quoteItemCount = window.checkoutConfig.quoteItemData.length;
                        if (quoteItemCount > 0) {
                            for (var i = 0; i < quoteItemCount; i++) {
                                if (window.checkoutConfig.quoteItemData[i].item_id == data) {
                                    window.checkoutConfig.quoteItemData[i].line_item_comment = rawComment;
                                }
                            }
                        }
                    }
                });
            });
            $body.on('focus', 'textarea', function() {
                var clsStrip = $(this).closest('.addnote-strip');
                clsStrip.find('.save-comment').removeClass('hidden');
                clsStrip.find('.close-comment').removeClass('hidden');
            });
            $body.on('click', '.close-comment', function() {
                var clsStrip = $(this).closest('.addnote-strip');
                clsStrip.find('.update-comment').addClass('hidden');
                clsStrip.find('.close-comment').addClass('hidden');
                var comment = clsStrip.find('textarea').attr('data-comment');
                if (comment.length <= 0) {
                    var note = clsStrip.find('label');
                    note.trigger('click');
                    note.next('.collapse').removeClass('in');
                    if (clsStrip.prev('.addnotebtnlabel').find('.addnotebtn').length > 0) {
                        clsStrip.prev('.addnotebtnlabel').removeClass('hidden');
                        clsStrip.addClass('hidden');
                        note.trigger('click');
                        note.next('.collapse').addClass('in');
                    }
                }
                clsStrip.find('textarea').val(comment);
            });
            $body.on('click', '.addnotebtnlabel', function() {
                $(this).closest('.addnotebtnlabel').addClass('hidden').next('.addnote-strip').removeClass('hidden');
            });

            window.wireCuts = {};
            function extractWireCuts(productId, commentString) {
                var wireCutsComment = commentString.replace(/(.*?)(CW\|)((?:[0-9]+\|?)*)/s, '$3');
                var cuts = wireCutsComment.split('|');
                for (var c in cuts) {
                    if (cuts.hasOwnProperty(c)) {
                        if (cuts[c] === '') {
                            cuts.splice(c, 1);
                        }
                    }
                }
                wireCuts[productId] = cuts;
                return commentString.replace(/(.*?)(CW\|)((?:[0-9]+\|?)*)/s, '$1');
            }

            function processWireCuts(target) {
                if (typeof target.get(0) == 'undefined') {
                    return false;
                }
                var productId = target.data('id');
                var clsStrip = target.closest('.addnote-strip');
                var commentInput = clsStrip.find('textarea');
                var rawComment = commentInput.val();
                commentInput.val(extractWireCuts(productId, rawComment));
                updateWireCuts(target);
            }

            function saveWireCuts(target) {
                if (typeof target.get(0) == 'undefined') {
                    return false;
                }
                var productId = target.data('id');
                var clsStrip = target.closest('.addnote-strip');
                var rawComment = clsStrip.find('textarea').val();
                if (typeof wireCuts[productId] != 'undefined' && wireCuts[productId].length > 0) {
                    var wireCutsComment = (rawComment.length > 0 ? '\n': '') + 'CW' + wireCuts[productId].join('|');
                    clsStrip.find('textarea').val(rawComment + wireCutsComment);
                }
            }

            function getQtyCount (item_id) {
                var quoteItems = quote.getItems();
                var qty = 0;
                for (var i = 0; i < quoteItems.length; i++) {
                    if (quoteItems[i].item_id == item_id) {
                        qty = quoteItems[i].qty;
                        return qty;
                    }
                }
            }

            function updateWireCuts(target) {
                if (typeof target.get(0) == 'undefined') {
                    return false;
                }
                var productId = target.data('id');
                var id = target.data('id');
                var maxQty = parseInt(getQtyCount(id));
                var ticks = wireCuts[productId];
                var wireCutsList = target.find('#wireCuts-' + productId);
                if (wireCuts[productId].length > 0) {
                    wireCutsList.html('');
                    for (const i in wireCuts[productId]) {
                        if (wireCuts[productId].hasOwnProperty(i)) {
                            maxQty -= wireCuts[productId][i];
                             wireCutsList.append(
                                '<li class="wireCut">' +
                                wireCuts[productId][i] +
                                'ft <a href="javascript:void(0)" class="text-danger removeCutLength" data-cid="' +
                                i +
                                '" title="Remove"><i class="fa fa-times"></i></a></li>'
                            );
                        }
                    }
                }
                if (maxQty <= 0) {
                    target.find('#wireCutsAddWrapper-' + productId).addClass('hidden');
                    target.find('#addCutLength-' + productId).addClass('hidden');
                } else {
                    var wireCutInput = target.find('#wireCutLength-' + productId);
                    wireCutInput.attr('max', maxQty);
                    var lastCutLength = parseInt(wireCutInput.val());
                    if (lastCutLength > maxQty) {
                        wireCutInput.val(maxQty);
                    }
                    target.find('#wireCutsAddWrapper-' + productId).removeClass('hidden');
                    target.find('#addCutLength-' + productId).removeClass('hidden');
                }
            }

            $body.on('click', '.addCutLength', function() {
                var thisCut = $(this);
                var thisParent = thisCut.closest('.wireCutWrapper');
                var productId = thisParent.data('id');
                if (typeof wireCuts[productId] == 'undefined') {
                    wireCuts[productId] = [];
                }
                var wireCutInput = thisParent.find('#wireCutLength-' + productId);
                var wireCutLength = wireCutInput.val();
                wireCuts[productId].push(wireCutLength);
                updateWireCuts(thisParent);
                thisParent.find('.update-comment').removeClass('hidden');
            });

            $body.on('click', '.add-comment-field', function() {
                var thisCut = $(this);
                var id = thisCut.data('id');
                var maxQty = $("#wireCutWrapper-"+id).data('qty');
                $('#wireCut-comment-fields-'+id).append('<div class="note-fields"><div class="field"><input name="wireCut_'+id+'[]" type="number" class="wireCut getCutLengthsCount form-control" data-id="'+id+'" id="wireCut-'+id+'" value="1" min="1" max="'+maxQty+'"></div><div class="field"><input name="wireLength_'+id+'[]" type="number" class="wireLength getCutLengthsCount form-control" data-id="'+id+'" id="wireLength-'+id+'" value="1" min="1" max="'+maxQty+'"></div><span class="remove-fields "><i class="fa fa-remove"></i></span></div>');
            });

            $body.on('click', '.remove-fields', function() {
                var thisCut = $(this);
                $($(this)).parent().remove();
                $(".getCutLengthsCount").trigger('focusout'); 
            });

            $body.on('click', '.save-comment', function() {
                var wireCutWrapper = $(this).closest('.comment-container').find('.wireCutWrapper');
                wireCutWrapper.find('.update-comment').removeClass('hidden');
                var thisCut = $(this);
                var id = thisCut.data('id');
                $(".error_div_"+id).addClass("hidden");
                var cartQty = $("#cart-"+id+"-qty").val() ? $("#cart-"+id+"-qty").val() : getQtyCount(id);
                var allWireLengthCounts = allWireLengthCountsFunctoin(id);
                var commentInput = allWireLengthCounts['commentInput'];
                allWireLengthCounts = allWireLengthCounts['allWireLengthCounts'];
                var obj = customerData.get('cart')().items;
                    var isCutWire = null;
                    var array = $.map(obj, function(value, index) {
                        if(value.item_id == id){
                            isCutWire = value.isCutWire;
                            return false; 
                        }
                    });
                
                var wireCutsComment = '';
                if(parseInt(allWireLengthCounts) != parseInt(cartQty) && isCutWire == true){
                    $(".error_div_"+id).removeClass("hidden");
                    return false;
                }
                /* Remove last Pipe | symbol */
                commentInput = commentInput.slice(0,-1);
                var commentVal = $('#product-item-comment-'+id).val().split("CW");
                var commentText =  (typeof commentVal[0] == 'undefined' ? '': commentVal[0]);
                
                if(commentInput.length > 0){
                    var commentValue = commentVal[0].replace(/\n|\r/g,'');
                    wireCutsComment = commentValue +''+ (commentValue.length > 0 ? '\n': '') + 'CW|' + commentInput;
                }else{
                    var wireCutsNote = '';
                    var wireCuts = (typeof $("#product-item-comment-"+id).data('cuts') == 'undefined' ? '': $("#product-item-comment-"+id).data('cuts'));
                    if($("#wireCut-comment-fields-"+id).length == 0 && wireCuts.length > 3) {
                        wireCutsNote =  (typeof wireCuts == '' ? '': "CW"+wireCuts);
                    }
                    wireCutsComment = $('#product-item-comment-'+id).val()+'\n'+wireCutsNote;
                }
                
                var quoteId = $('#quoteId').val();
                if (typeof quoteId == 'undefined') {
                    quoteId = $('#quoteId-' + id).val();
                }
                
                var homeUrl = window.location.origin;
                var dataString = 'comment=' + wireCutsComment + '&id=' + id + '&qid=' + quoteId;
                if(wireCutsComment.length > 0){
                    $.ajax({
                        url: homeUrl + '/cartoption/index/addcomment',
                        type: 'POST',
                        showLoader: true,
                        data: dataString,
                        success: function (data) {
                            $('#update-comment-' + data).addClass('hidden');
                            $('#close-comment-' + data).addClass('hidden');
                            $('#product-item-comment-' + data).attr('data-comment', wireCutsComment);
                            if (wireCutsComment.length <= 0) {
                                $('#label-' + data).trigger('click');
                                $('#comment-container-' + data).removeClass('in');
                                if ($('#addnotebtn-' + data).length > 0) {
                                    $('#addnotebtnlabel' + data).removeClass('hidden');
                                    $('#container-' + data).addClass('hidden');
                                    $('#label-' + data).trigger('click');
                                    $('#comment-container-' + data).addClass('in');
                                }
                            }
                            processWireCuts(wireCutWrapper);
                            var quoteItemCount = window.checkoutConfig.quoteItemData.length;
                            if (quoteItemCount > 0) {
                                for (var i = 0; i < quoteItemCount; i++) {
                                    if (window.checkoutConfig.quoteItemData[i].item_id == data) {
                                        window.checkoutConfig.quoteItemData[i].line_item_comment = wireCutsComment;
                                         window.checkoutConfig.quoteItemData[i].qty = allWireLengthCounts;
                                    }
                                }
                            }
                        }
                    });
                }
            });
            
            $body.on('click', '.removeCutLength', function() {
                var thisCut = $(this);
                var thisParent = thisCut.closest('.wireCutWrapper');
                var productId = thisParent.data('id');
                var cutId = thisCut.data('cid');
                if (typeof wireCuts[productId][cutId] != 'undefined') {
                    wireCuts[productId].splice(cutId, 1);
                }
                updateWireCuts(thisParent);
                thisParent.find('.update-comment').removeClass('hidden');
            });

            $('.wireCutWrapper').each(function() {
                processWireCuts($(this));
                pageloadshowinputs($(this));
                $(this).closest('.addnote-strip').find('.addnote').trigger('click');
            });
            
            $body.on('change focusout', '.form-control.lp-qty-input.input-text.qty', function() {
                var thisCut = $(this);
                var id = thisCut.data('cart-item');
                qtyChange(id);
            });

            function qtyChange(id) {
                setTimeout(function() {
                    var qty = $("#cart-"+id+"-qty").val() ? $("#cart-"+id+"-qty").val() : getQtyCount(id);
                    $("#wireCutWrapper-"+id).data('qty',qty);
                    $("#wireCut-"+id).trigger('focusout');
                }, 100);
            }

            $body.on('click', '.input-group-addon.fa.btn-number', function() {
                var thisCut = $(this);
                var id = thisCut.data('cart-item');
                qtyChange(id);
            });

            function qtyChange(id) {
                setTimeout(function() {
                    var qty = $("#cart-"+id+"-qty").val() ? $("#cart-"+id+"-qty").val() : getQtyCount(id);
                    $("#wireCutWrapper-"+id).data('qty',qty);
                    $("#wireCut-"+id).trigger('focusout');
                }, 100);
            }
            
            $body.on('click', '.input-group-addon.fa.btn-number', function() {
                var thisCut = $(this);
                var id = thisCut.data('cart-item');
                qtyChange(id);
            });

            $body.on('keyup', '.getCutLengthsCount', function() {
                if (/\D/g.test(this.value))
                  {
                    // Filter non-digits from input value.
                    this.value = this.value.replace(/\D/g, '');
                  }
            });
            
            $body.on('focusout', '.getCutLengthsCount', function() {
                var thisCut = $(this);
                var id = thisCut.data('id');
                var cartQty = $("#cart-"+id+"-qty").val() ? $("#cart-"+id+"-qty").val() : getQtyCount(id);
                var allWireLengthCounts = allWireLengthCountsFunctoin(id);
                var commentInput = allWireLengthCounts['commentInput'];
                allWireLengthCounts = allWireLengthCounts['allWireLengthCounts'];
                $('#total-lengths-qty-'+id).html(allWireLengthCounts);
                if(!isNaN(allWireLengthCounts) && !isNaN(cartQty)) {
                    if(parseInt(allWireLengthCounts) == parseInt(cartQty)){
                        $(".error_div_"+id).addClass("hidden");
                        $("#btn-proceed-checkout").removeClass("disabled");
                        $("#save-comment-"+id).trigger("click");
                        $("#btn-proceed-checkout").removeAttr('disabled');
                    }else{
                        $(".error_div_"+id).removeClass("hidden");
                        $("#btn-proceed-checkout").addClass("disabled");
                        $("#btn-proceed-checkout").attr('disabled','disabled');
                    }
                    if(allWireLengthCounts > cartQty){
                        $("#add-comment-"+id).addClass("hidden");
                        
                    }else{
                        $("#add-comment-"+id).removeClass("hidden");
                    }
                }
            });
            
            function allWireLengthCountsFunctoin(id) {
               var wireLengths = $("input[name='wireLength_"+id+"[]']").map(function(){return $(this).val();}).get();
                var wireCutsCount = $("input[name='wireCut_"+id+"[]']").map(function(){return $(this).val();}).get();
                var commentInput ='';
                var allWireLengthCounts = 0;
                var wireLengthsVal = 0;
                if(wireLengths.length == wireCutsCount.length){
                    var i = wireLengths.length - 1;
                    for (i; i >= 0; i--) {
                        var wireLengthCounts = '';
                        var j = wireCutsCount[i];
                        for ( j; j >= 1; j--) {
                            wireLengthCounts = wireLengths[i]+'|'+wireLengthCounts;
                            if(isNaN(wireLengths[i])){
                                wireLengthsVal = 0;
                            }else{
                                wireLengthsVal = wireLengths[i];
                            }
                            allWireLengthCounts = parseInt(allWireLengthCounts) + parseInt(wireLengthsVal);
                        }
                        commentInput = commentInput+''+wireLengthCounts;
                    }
                }
                const array = { "commentInput" : commentInput, "allWireLengthCounts" : allWireLengthCounts};
                return array;
            }

            function pageloadshowinputs(target){
                if (typeof target.get(0) == 'undefined') {
                    return false;
                }

                var productId = target.data('id');
                var id = target.data('id');
                var maxQty = parseInt(target.data('qty'));
                var ticks = wireCuts[productId];
                var commanLengthsArray= $.map(ticks, function(value, index) {
                    return [value];
                }); 

                var cartQty = getQtyCount(id);
                const counts = {};
                var allWireLengthCounts = 0;
                commanLengthsArray.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
                if(Object.keys(counts).length > 0){
                    var x = 0;
                    for (const j in counts) {
                        if (counts.hasOwnProperty(j)) {
                            allWireLengthCounts += parseInt(counts[j]) * parseInt(j);
                            if(x == 0){
                                $('#wireCut-'+id).val(counts[j]);
                                $('#wireLength-'+id).val(j);
                            }else{
                                 $('#wireCut-comment-fields-'+id).append('<div class="note-fields"><div class="field"><input name="wireCut_'+id+'[]" type="number" class="wireCut getCutLengthsCount form-control" data-id="'+id+'" id="wireCut-'+id+'" value="'+counts[j]+'" min="1" max="'+cartQty+'"></div><div class="field"><input name="wireLength_'+id+'[]" type="number" data-id="'+id+'" class="wireLength getCutLengthsCount form-control" id="wireLength-'+id+'" value="'+j+'" min="1" max="'+cartQty+'"></div><span class="remove-fields "><i class="fa fa-remove"></i></span></div>');
                            }
                            x++; 
                        }
                    }
                }else{
                    $('#wireCut-'+id).val(1);
                    $('#wireLength-'+id).val(cartQty);
                    allWireLengthCounts = cartQty;
                }
                $('#total-lengths-qty-'+id).html(allWireLengthCounts);
                if(!isNaN(allWireLengthCounts) && !isNaN(cartQty)) {
                    if(parseInt(allWireLengthCounts) == parseInt(cartQty)){
                        $(".error_div_"+id).addClass("hidden");
                        $("#btn-proceed-checkout").removeClass("disabled");
                        $(".add-comment-field").prop("disabled", false);
                        $("#btn-proceed-checkout").removeAttr('disabled');

                    }else{
                        $(".error_div_"+id).removeClass("hidden");
                        $("#btn-proceed-checkout").addClass("disabled");
                        $(".add-comment-field").prop("disabled");
                        $("#btn-proceed-checkout").attr('disabled','disabled');
                    }
                }
            }

        });
    }
);

