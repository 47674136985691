define(
    'Magento_Checkout/js/view/summary/grand-total',[
        'Magento_Tax/js/view/checkout/summary/grand-total'
    ],
    function (Component) {
        'use strict';

        return Component.extend({

            /**
             * @override
             */
            isDisplayed: function () {
                return true;
            }
        });
    }
);
