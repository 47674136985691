define('Usesi_Customer/js/enable-captcha',['jquery'],
    function ($) {
        "use strict";
        var scriptLoaded = false;
        /**
         * Account Creation Page
         */
        if (window.location.pathname == "/customer/account/create") {
            enableCaptcha();
        }
        /**
         * Account Creation
         */
        $('body').on('click', '#customer-register', function (e) {
            enableCaptcha();
        });

        /**
         * Newsletter subscription
         */
        $('body').on('focus', '#newsletter-validate-detail .enableRecaptcha', function() {
            enableCaptcha();
        });

        function enableCaptcha() {
            if (!scriptLoaded) {
                $.getScript("https://dkh7g0nks5j70.cloudfront.net/google/js/recaptcha/api.js");
                scriptLoaded = true;
            }
        }
    }
);
