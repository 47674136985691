/**
 * Copyright © 2013-2017 Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
define('Magento_Theme/js/view/messages',[
    'jquery',
    'uiComponent',
    'underscore',
    'Magento_Customer/js/customer-data',
    'jquery/jquery-storageapi'
], function ($, Component, _, customerData) {
    'use strict';

    return Component.extend({
        defaults: {
            cookieMessages: [],
            messages: []
        },

        /** @inheritdoc */
        initialize: function () {
            this._super();
            // To fix the plus symbol present in the magento messages
            var messageArray = [];
            this.cookieMessages = _.unique($.cookieStorage.get('mage-messages'), 'text');
            if (this.cookieMessages) {
                var resultMsg = '';
                var magentoMessage = '';
                for (var r = 0; r < this.cookieMessages.length; r++) {
                    var strFind = "+";
                    magentoMessage = this.cookieMessages[r].text;
                    if (magentoMessage.indexOf(strFind) != -1) {
                        resultMsg = magentoMessage.split("+").join(" ");
                    } else {
                        resultMsg = this.cookieMessages[r].text;
                    }
                    messageArray.push({
                        type: this.cookieMessages[r].type,
                        text: resultMsg
                    });
                }
            }
            this.cookieMessages = messageArray;
            //End
            this.messages = customerData.get('messages').extend({
                disposableCustomerData: 'messages'
            });

            if (!_.isEmpty(this.messages().messages)) {
                customerData.set('messages', {});
            }

            $.cookieStorage.set('mage-messages', '');
            window.addEventListener('beforeunload', function (e) {
                $.cookieStorage.set('mage-messages', '');
                customerData.set('messages', {});
            });
        }
    });
});
