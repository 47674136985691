define('Punchout_Gateway/js/model/checkout-data-resolver-mixin',[
    'Magento_Checkout/js/checkout-data'
], function(checkoutData) {
    'use strict';

    return function(originalCheckoutDataResolver) {
        //#1. Check is PunchOut
        if (!window.checkoutConfig.is_punchout
            || !window.checkoutConfig.punchout
            || !window.checkoutConfig.punchout.shipping
        ) {
            return originalCheckoutDataResolver;
        }

        //#2. Set POSR shipping address
        checkoutData.setShippingAddressFromData(
            window.checkoutConfig.punchout.shipping
        );

        //#3. Set POSR Inspect/Edit specific data
        var op = window.checkoutConfig.punchout.operation;
        var selected_shipping_rate = window.checkoutConfig.punchout.selected_shipping_rate;
        if (selected_shipping_rate && (op == 'inspect' || op == 'edit')) {
            checkoutData.setSelectedShippingRate(selected_shipping_rate);
        }

        return originalCheckoutDataResolver;
    }

});
