define('Usesi_PushNotifier/js/fcmToken',[
    'jquery','mobilestatus'
], function ($,mobilestatus) {
    'use strict';
    let token = '';
    return {
        update: function(token,type) {
            var osType = '';
            if(type.indexOf('android') != -1) {
                osType = 'android';
            }else {
                osType = 'ios';
            }
            return new Promise((resolve, reject) => {
                if (!token.length || !osType.length) {
                    return;
                }
                $.ajax({
                    url: window.BASE_URL + 'pushnotifier/index/updatetoken',
                    type: 'POST',
                    data: {
                        'token': token,'osType':osType
                    },
                    success: function (data) {
                        if (data && typeof data['errors'] != 'undefined') {
                            resolve(data['errors']);
                        } else {
                            reject('Query unsuccessful');
                        }
                    },
                    error: reject,
                })
            })
        }
    }
});

