define('js/custom',[
    'jquery',
    'jquery/ui',
    'moment',
    'mobilestatus',
    'Magento_Ui/js/modal/alert',
    'moment-timezone-with-data',
    'bootstrap',
    'mmenu',
    'jquery/jquery.cookie',
    'enable-captcha'
], function ($, ui, moment, mobilestatus, alert) {
    $(document).ready(function ($) {
        /* To notify mobile app about the action */
        mobilestatus();
        /*SHOP BY LIST & SHOP BY CATEGORY TOGGLE IN HOME PAGE SECTION*/
        $(document).on('click', '#shop-by-list-toggle, #shop-by-category-toggle', function(e) {
            e.preventDefault();
            var eventName = ($(this).attr('id') === 'shop-by-list-toggle') ? 'ShopByList' : 'ShopByCategory';
            if (window.hasOwnProperty('webkit')) {
                window.webkit.messageHandlers.ClickHandler.postMessage({Event:eventName})
            } else if (window.hasOwnProperty('ClickHandler')) {
                window.ClickHandler.postMessage(JSON.stringify({Event:eventName}));
            } else if (!$(this).hasClass("disabled")) {
                $("#mobile-menu").data("mmenu").open();
                var elementName = (eventName === 'ShopByList') ? $('.shopby-list>a') : $('.mob-shopby>a');
                elementName.trigger( "click" );
            }
        });
        /* Check localstorage limit exceed and refresh while exceed the limit */
        try {
            localStorage.removeItem('storage_data');
            localStorage.setItem('storage_data', ('*').repeat(50000));
            localStorage.removeItem('storage_data');
        } catch (e) {
            var quotaExceeded = false;
            if (e) {
                if (e.code) {
                    switch (e.code) {
                        case 22:
                            quotaExceeded = true;
                            break;
                        case 1014:
                            /* Firefox */
                            if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
                                quotaExceeded = true;
                            }
                            break;
                    }
                } else if (e.number === -2147024882) {
                    /* Internet Explorer 8 */
                    quotaExceeded = true;
                }
            }
            if (quotaExceeded) {
                localStorage.clear();
                $('#messageModal').find('.modal-body').html('<i class="fa fa-spinner"></i> Updating your shopping cart');
                $('#messageModal-Button, .close').hide();
                $('#messageModal-Button').trigger('click');
                setTimeout(function () {
                    $('#messageModal').hide();
                    $('body').removeClass('modal-open');
                    $('.modal-backdrop').remove();
                    location.reload();
                }, 1000);
            }
        }

        /* Check Shoppinglist Menu */
        if (typeof window.getLocalStorage != 'undefined') {
            var wishlistData = JSON.parse(window.getLocalStorage('wishlistData', true));
            if (typeof wishlistData != 'undefined' && wishlistData != null && wishlistData.info.trim().length > 0) {
                $('.shopby-list').removeClass('hidden');
            }
        }
        /*Change Layout*/
        var b2bTemplate = $('.b2bhome-content-container');
        var layout = $.cookie('usesiLayout');
        if (b2bTemplate.length > 0) {
            $('.b2bhome-content-container , .b2bhome-minibanner-container').addClass('hidden');
            if (layout == 'B2B') {
                $('.b2bhome-content-container, .b2bhome-minibanner-container').removeClass('hidden');
                $('.b2bhome-content-container, .b2bhome-minibanner-container').addClass('b2bajaxload');
                $('.b2bhome-content-container, .b2bhome-minibanner-container').slideDown(1000);
            }
        }
        if (window.location.pathname != "/checkout/") {
            if (document.readyState == 'complete') {
                update_customer_details();
            } else {
                window.addEventListener("load", update_customer_details);
            }
        }
        /*
         * update customer details on header
         */
        function update_customer_details() {
            $.ajax({
                url: '/customer/account/account'
            }).done(function (data, textStatus, jqXHR) {
                var content = JSON.parse(data);
                if (content.isAccountInactive == true) {
                    let accountClosedAlert = alert({
                        modalClass: 'mage-bs',
                        title: $.mage.__('Invalid Account'),
                        content: $.mage.__('InvalidAccount'),
                        closed: function (){
                            window.location.href = "/customer/account/logoutSuccess";
                        },
                        buttons: [{
                            text: $.mage.__('Close'),
                            class: 'btn btn-primary'
                        }]
                    });
                    $('.mage-bs').find('.modal-inner-wrap').addClass('modal-xs');
                }
                $('#mmenu-address').replaceWith(content.mmenuAddress);
                $(".mmenu-account.logged-out").hide();
                if (content.isLoggedIn) {
                    $(".mmenu-account.logged-in").show();
                } else {
                    $(".mmenu-account.logged-out").show();
                }
                $('#user-account-information').html(content.header);
                $('.link-login-reg').attr('href',window.loginUrl);

                if (content.user) {
                    if(!content.orderHistoryLink){
                        $('.b2bhome-content-container , .order-history-link').addClass('hidden');
                    }
                    var b2bAddress = content.b2bhomeAddress;
                    var b2bhomeParent = $('.b2bhome-content');
                    b2bhomeParent.find('.b2bhome-id').html(b2bAddress.id);
                    var address = '<div><strong>' + b2bAddress.name + '</strong></div>';
                    address += '<div>' + b2bAddress.line1;
                    if (b2bAddress.line2) {
                        address += '</div><div>' + b2bAddress.line2 + ', ' + b2bAddress.city + '</div>';
                    } else {
                        address += ', ' + b2bAddress.city + '</div>';
                    }
                    address += '<div>' + b2bAddress.state + ' ' + b2bAddress.zip + '</div>';
                    b2bhomeParent.find('.b2bhome-address').html(address);
                    b2bhomeParent.find('.b2bhome-company').html(content.b2bCompanyName);
                    b2bhomeParent.find('.b2bhome-name').html(content.b2bFullName);
                    $('#b2b_account_entity_id').val(content.b2bBillToId);
                    $('#account-details').html(
                        b2bAddress.id + ' ' + content.b2bCompanyName
                    );
                    b2bhomeParent.find('.c-logo').removeClass('has-logo');
                    b2bhomeParent.find('.c-logopunch').addClass('hidden');
                    if(content.b2bimage){
                        b2bhomeParent.find('.c-logo').addClass('has-logo');
                        b2bhomeParent.find('.c-logo').css('background-image','url('+content.b2bimage+')');
                        $('.b2b-logo-preview').attr('src',content.b2bimage);
                        b2bhomeParent.find('.c-logo').data('img', content.b2bimage);
                        b2bhomeParent.find('.c-logo').removeClass('hidden');
                        b2bhomeParent.find('.c-logo').hasClass('c-logopunch') ? b2bhomeParent.find('.c-logo .fa-pencil').addClass('hidden') : '';
                    }

                    $('#b2bheader_salescontacts').html(content.b2bSalesContacts);
                    $('.b2bhome-content-container, .b2bhome-minibanner-container').removeClass('hidden');
                    $('.b2bhome-content-container, .b2bhome-minibanner-container').removeClass('b2bajaxload');
                    $.cookie('usesiLayout', 'B2B', {path: '/', domain: window.location.hostname});
                } else {
                            if ($('.b2bhome-content-container').length > 0) {
                                $('.b2bhome-content-container').addClass('hidden');
                                if (content.b2cUser) {
                                    $.cookie('usesiLayout', 'B2C', {path: '/', domain: window.location.hostname});
                                } else {
                                    $.cookie('usesiLayout', '', {path: '/', domain: window.location.hostname, expires: -1});
                                }
                            }
                        }
                    });
        }

        if (!localStorage.isBranchCheckDone && !window.isEmployeeLoggedIn) {
            $.ajax({'url': '/BranchLocator/index/branchset'})
                    .done(function (data, textStatus, jqXHR) {
                        localStorage.isBranchCheckDone = true;
                        $('.branch-name').html(data);
                    });
        }
        /**
         * Set the timezone of the user in COOKIE to retrieve in PHP - for Pickup delivery data process
         */
        var localTimeZone = moment.tz.guess();
        var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
        var timezone = (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
        var samesite = (window.useCookieSameSite) ? 'SameSite=None;Secure' : '';
        document.cookie = 'usertmz=' + timezone + '; path=/;' + samesite;
        document.cookie = 'localtmz=' + localTimeZone + '; path=/;' + samesite;

        window.customerHeartbeat = function (pagePerformance = '') {
            var lastHeartbeatTimestamp = localStorage.getItem('lastHeartbeat');
            if (Date.now() - lastHeartbeatTimestamp < 60000) {
                return;
            }
            localStorage.setItem('lastHeartbeat', Date.now());
            var formkey = $.cookie('form_key');
            $.ajax({
                'url': '/customer/account/heartbeat',
                data: {'form_key': formkey, pagePerformance},
                type: 'POST'
            }).done(function (data) {
                var resultData = JSON.parse(data);
                if (!resultData.isAuthorized) {
                    location.reload();
                }
                if (resultData.updateformkey) {
                    $('[name="form_key"]').val(resultData.form_key);
                }
                if ($.cookie('redirect_to_emp_search')) {
                    window.location.href = "/employee/search";
                }
            });
        };
        window.addEventListener("load", function () {
            /** Fix to clear multiple form_key cookies */
            document.cookie = "form_key=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"
            setTimeout(function() {
                customerHeartbeat({
                    url: window.location.href,
                    loadTime: window.performance.timing.loadEventEnd-window.performance.timing.navigationStart
                });
            });
            if (+HEARTBEAT_STATUS) {
                window.customerHeartbeatInterval = setInterval(customerHeartbeat, +HEARTBEAT_FREQUENCY);
            }
        });

        var w_h = $(window).height();

        // set focus to search
        setTimeout(function () {
            $('#search-popup-input').trigger('focus');
        });

        $('body').on('click','.modals-overlay', function (e) {
            $(".action-close").trigger("click");
        });
        // menu
        $('body').on('click', function (e) {
            $('.active-menu').removeClass('active-menu');
            close_type_ahead(e);
            $('.cms-nav-list').find('.hover').removeClass('hover');
        });

        $('.category-container ul').on('mouseenter', function () {
            var _parentul = $(this).closest('ul');
            if(_parentul.length > 0) {
                _parentul.addClass('hoverd');
            }
            $(this).removeClass('hoverd');
        });

        $('.shopby').on('click', function (e) {
            e.stopPropagation();
            if ($(this).hasClass('loaded')) {
                $(this).closest('ul').toggleClass('active-menu');
                close_bts_togglemenu();
                $('#btn-minicart-close').trigger('click');
                close_type_ahead(e);
                $('.cms-nav-list').find('.hover').removeClass('hover');
            }
        });

        $('.shopby-list, .shopby-brand').on('click', function (e) {
            e.stopPropagation();
            $(this).toggleClass('hover');
            close_bts_togglemenu();
            $('#btn-minicart-close').trigger('click');
            close_type_ahead(e);
            close_menu();
        });

        $('.shopby-list, .shopby-brand').on('mouseleave', function (e) {
            $(this).removeClass('hover');
        });

        $('.wishlistby').on('click', function (e) {
            e.stopPropagation();
            $('.wishlistmenu-container').toggle();
            $('.wishlistbyul').toggleClass('active-menu');
        });

        var sm_h = w_h - ($('.page-header').height() + 86);
        $('.nav-sections .submenu-wrap').css('max-height', sm_h);

        $('.nav-left-links').detach().insertBefore('.nav-right-links');
        $('.cms-nav').detach().insertAfter('.category-container');
        var cms_nav = $('.cms-nav-list').html();
        $('.mmenu-list .mob-shopby').after(cms_nav);

        function close_menu() {
            var active_menu = $('.navigation').find('.active-menu');
            if (active_menu.length) {
                active_menu.removeClass('active-menu');
            }
        }

        function close_bts_togglemenu() {
            $('.dropdown').removeClass('open');
        }

        function close_type_ahead(e) {
            if (!$(e.target).closest('.typeahead-container').length) {
                if (!$(e.target).hasClass('search-popup-input')) {
                    $('#typeahead-search-wrapper .typeahead').removeClass('typeahead_show');
                }
            }
        }

        var pb_ele = $('.page-bottom');
        var c_ele = $('.compare-basket');
        $(window).on('scroll',function () {
            var scrollEle = $(this);
            if (scrollEle.scrollTop() > 100) {
                $('.page-header .header').addClass('fix');
                $('body').addClass('header-fix');
                $('#toTop').fadeIn();
            } else {
                $('.page-header .header').removeClass('fix');
                $('body').removeClass('header-fix');
                $('#toTop').fadeOut();
            }

            if (c_ele.length > 0) {
                var ele_t = pb_ele.offset().top;
                var w_t = scrollEle.scrollTop();
                var reached = ((w_t + w_h) >= ele_t) ? true : false;
                if (c_ele.length > 0) {
                    reached ? c_ele.addClass('reached') : c_ele.removeClass('reached');
                }
            }
        });

        $('.minicart-wrapper .showcart').on('click', function (e) {
            close_menu();
            close_bts_togglemenu();
            $('.cms-nav-list').find('.hover').removeClass('hover');
        });

        // initiates bs popover and tooltip
        $('[data-toggle="popover"]').popover();
        $("body").tooltip({
            selector: '[data-toggle="tooltip"]'
        });
        $('.dropdown-menu').on("click.bs.dropdown", function (e) {
            var t = $(e.target);
            if (!t.is(".btn")) {
                e.stopPropagation();
            }
        });

        var topBarTour = $(".topbar_tour_link.shake");
        function add_active() {
            topBarTour.addClass('active');
            setTimeout(function () {
                remove_active();
            }, 2000);
        }

        function remove_active() {
            topBarTour.removeClass('active');
            setTimeout(function () {
                add_active();
            }, 5000);
        }
        add_active();

        // password visible
        $('.pwd-opt').on('click', function () {
            var ele = $(this),
                    pwd_input = ele.closest('.password').find('.form-control');
            if (pwd_input.attr('type') == 'password') {
                pwd_input.attr('type', 'text');
                ele.removeClass('fa-eye-slash').addClass('fa-eye');
            } else {
                pwd_input.attr('type', 'password');
                ele.removeClass('fa-eye').addClass('fa-eye-slash');
            }
        });

        // stick modal-open class while modal is open
        $(document).on('hidden.bs.modal', function (event) {
            if ($('.modal:visible').length) {
                $('body').addClass('modal-open');
            }
        });

        $('#toTop').on('click',function () {
            $("html, body").animate({
                scrollTop: 0
            }, 600);
            return false;
        });

        // reset modal data
        $('.modal').on('hidden.bs.modal', function () {
            var ele = $(this),
                    aLF = $('#usesi-ajaxloginForm');
            ele.find('form').trigger('reset');
            ele.find('.message').removeClass("message-error error");
            ele.find('.messages').hide();
            aLF.find('.message').removeClass("message-error error");
            aLF.find('.messages').hide();
        });

        // my dashboard sidebar toggle on mobile
        $('.account-nav .title').on('click', function () {
            $(this).toggleClass('active').next('.content').slideToggle();
        });

        $('#email_login').on('blur', function () {
            $(this).val($(this).val().trim());
        });

        //To set if add_all_to_cart class is not set at the shopping list add all to cart
        if (!$('.wishlist-index-index .tocart').hasClass('all_to_cart')) {
            $('.wishlist-index-index .tocart').addClass('all_to_cart');
        }
        //End

        // PDP sticky side bar Start
        var $sticky = $('.sticky.nav-to');
        var $stickyrStopper = $('.page-footer .footer');
        if (!!$sticky.offset()) { // make sure ".sticky" element exists
            var generalSidebarHeight = $sticky.height();
            var stickyTop = $sticky.offset().top;
            var stickOffset = 140;
            var stickyStopperPosition = $stickyrStopper.offset().top;
            var stopPoint = stickyStopperPosition - generalSidebarHeight - stickOffset;
            var diff = stopPoint - generalSidebarHeight - 540;
            $(window).on('scroll',function () { // scroll event
                var windowTop = $(window).scrollTop(); // returns number
                if (stopPoint < windowTop) {
                    $sticky.css({position: 'absolute', top: diff});
                } else if (stickyTop < windowTop + stickOffset) {
                    $sticky.css({position: 'fixed', top: stickOffset});
                } else {
                    $sticky.css({position: 'absolute', top: 'initial'});
                }
            });

        }
        // PDP sticky side bar End

       $('.navto-sec > h3').on('click', function(){
           $('.navto-sec').not($(this).closest('.navto-sec')).removeClass('active');
           $(this).closest('.navto-sec').toggleClass('active');
       });

        $('.close-active').on('click', function () {
            $(this).closest('.filter-by-sec').removeClass('active');
        });

        // close custom popups on ESC key
        $(document).on('keyup', function (e) {
            if (e.which === 27) {
                close_menu();
                close_bts_togglemenu();
                // Close typeahead //
                $('#typeahead-search-wrapper .typeahead').removeClass('typeahead_show');
                // Close typeahead //
                if ($('.mwishlist_mask').css('display') === "block") {
                    $('.mwishlist_popup_close').trigger('click');
                }
            }
        });

        // team page
        $('.member .trigger-det').on('click', function () {
            var mem = $(this).closest('.member');
            $('.member').not(mem).removeClass('active');
            mem.toggleClass('active');
        });
        $('.member-det .close').on('click', function () {
            $(this).closest('.member').toggleClass('active');
        });

        //linecard page
        $(".line-card-main .tb-list:first-child").addClass('active-tab').next('.tab-pane').addClass('active in');

        $(".line-card-main .tb-list").on('click', function () {
            var w_w = $(window).width();
            if (w_w > 767) {
                var _to = $(this).parent().offset().top;
                $('html,body').animate({
                    scrollTop: _to},
                        'slow');
            }
            $(".line-card-main .tb-list").removeClass('active-tab');
            $(this).addClass('active-tab');
        });

        $('.b-linker').on('click', function () {
            $('.b-linker').not(this).removeClass('active');
            $(this).addClass('active');
        });

        // equal grid
        function getMaxHeight(holder) {
            var h = 100;
            holder.find('.eql-grid').css('height', 'auto');
            holder.find('.eql-grid').each(function () {
                var this_h = $(this).height();
                h = (this_h > h) ? this_h : h;
            });
            return h;
        }
        var grid_holder = $('.eql-grid-holder');
        var grid_h = getMaxHeight(grid_holder);
        grid_holder.find('.eql-grid').css('height', grid_h);
        $(window).on('resize' , function () {
            grid_h = getMaxHeight(grid_holder);
            grid_holder.find('.eql-grid').css('height', grid_h);
        });

        /* Assign empty url value to the iframe src attribute when
         modal hide, which stop the video playing */
        $("#videoModal").on('hide.bs.modal', function () {
            $("#videoFrame").attr('src', '');
        });

        /* Assign the initially stored url back to the iframe src
         attribute when modal is displayed again */
        $("#videoModal").on('show.bs.modal', function (e) {
            var target = $(e.relatedTarget);
            var vidUrl = target.attr('href');
            $("#videoFrame").attr('src', vidUrl);
        });

        /* Newsletter message timeout in homepage */
        if (jQuery('.cms-home').length > 0) {
            setTimeout(function () {
                jQuery('.messages').hide();
            }, 30000);
        }

        /* branch popover */
        if ($(".branch-det-pop").length > 0) {
            $(".branch-det-pop").popover({
                html: true,
                container: 'body',
                content: function () {
                    return $(this).next('.branch-popover').html();
                }
            });
        }
        /* security seals on register */
        if ($('.register_security_seals').length > 0) {
            $('#usesi-ajaxregisterForm #usesi-register-form').append($('.register_security_seals').html());
        }

        $(document).on('click', '.minicart-items a[data-toggle="tooltip"]', function (e) {
            e.preventDefault();
            $('.tooltip').hide();
            $(this).tooltip('show');
        });

        /*rebate icon*/
        $(document).on('click', '.rebate > a', function () {
            window.open($(this).data('href'));
        });

        function getUrlVars() {
            var vars = {};
            var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
                vars[key] = decodeURIComponent(value);
            });
            return vars;
        }
        var $_GET = getUrlVars();
        if (typeof $_GET.ref != 'undefined') {
            var referer = $_GET.ref;
            if (referer == 1) {
                var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
                if (width < 992) {
                    window.location.href = 'customer/account/login' + '?refe=' + 1;
                } else {
                    $('#usesi-ajaxloginForm').btsmodal('show');
                }
            }
        }
        $('#usesi-ajaxloginForm').on('hide.bs.modal', function () {
            if (typeof $_GET.ref != 'undefined') {
                var newUrl = removeParam('ref');
                history.pushState(null, '', newUrl);
            }
        });
        function removeParam(key) {
            var sourceURL = location.href;
            var returnURL = sourceURL.replace(new RegExp(key + "=\\w+"),"").replace("?&","?").replace("&&","&");
            return returnURL;
        }
        /**
         * Typeahead Mobile Search
         */
        $('#mobile-search').on('click', function (e) {
            e.preventDefault();
            $('.search-popup-input').val('');
            $(this).toggleClass('active');
            $('.mob-block-search').toggleClass('active');
        });

        $('body').on('click', '#typeahead-search', function (e) {
            var searchField = $('.search-popup-input');
            searchField.trigger('keyup');
            var keyword = searchField.val();
            if (!keyword) {
                return;
            }

            if (typeof window.algoliaTypeaheadRedirect !== 'undefined') {
                window.location.href = window.algoliaTypeaheadRedirect;
            } else {
                window.location.href = "/algolia/keyword/index/?keyword=" + encodeURIComponent(keyword) + "&search=1";
            }
        });
        /**
         * Typeahead End
         */
    });
});

