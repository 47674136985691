define(
  'Magento_Theme/js/absolutefooter',[
    "jquery",
    "Magento_Customer/js/customer-data"
  ], function($, customerData) {

  var sections = ['cart'];
  customerData.invalidate(sections);

  $(document).ready(function() {
    // variables
    var featureTourUrl = $('.data-absolutefooter-featuretoururl').val();
    var cookieFeatureTour = $('.data-absolutefooter-cookiefeaturetour').val();
    var cookieFeatureTourLoggedIn = $('.data-absolutefooter-viewstatus').val();
    var isLoggedIn = $('.data-absolutefooter-customerIsLoggedIn').val();
    var featuretourviewstatus = $('.data-absolutefooter-featuretourviewstatus').val();

    $('.tour-ticket').on('click',function() {
      $.ajax({
        url: '',
        type: 'POST',
        data: {
          'value': 1
        },
        success: function(data) {
          $('.topbar_tour_link').removeClass('shake');
        },
        error: function(request, error) {}
      });
    });

    if (isLoggedIn) {
      if (cookieFeatureTourLoggedIn == featuretourviewstatus) {
        $('.topbar_tour_link').addClass('shake')
      }
    } else {
      if (cookieFeatureTour == featuretourviewstatus) {
        $('.topbar_tour_link').addClass('shake')
      }
    }

    $('#email_login,#customer-email,#newsletter').on('change', function() {
        var email = $(this);
        email.validation();
        if (email.validation('isValid') && typeof dmPt !== 'undefined') {
            window.dmPt('identify', email.val());
        }
    });

  });
});

