define('Magento_Theme/js/menu-updator',['jquery', 'mmenu'], function ($) {
    return function (params) {
        var fn = function () {

            if (window.getLocalStorage('catData', true)) {
                window.localStorage.removeItem("catData");
            }

            var menuData = validateCache(JSON.parse(window.getLocalStorage('menuData', true)));
            var wishlistData = validateCache(JSON.parse(window.getLocalStorage('wishlistData', true)));
            var brandMenu = validateCache(JSON.parse(window.getLocalStorage('brandMenu', true)));

            function checkExpiry(intervaltime, timestamp) {
                timestamp = timestamp || 0;
                if (!intervaltime) {
                    intervaltime = 60;
                }
                var currentTime = Date.now();
                var expiry = timestamp + (intervaltime * 60 * 1000);
                return currentTime > expiry;
            }

            function validateCache(data) {
                if (
                        typeof data == 'undefined'
                        || data == null
                        || checkExpiry(params.shopbyTimeinterval, data.timestamp) == true
                        ) {
                    return false;
                }
                return data;
            }

            function initMenu() {
                if (menuData && wishlistData) {
                    $('#shop-by-category-toggle').removeClass('disabled');
                    $('.category-container').html(menuData.desk);
                    $('.shopby').addClass('loaded');
                    $(".mob-shopby-child").append(menuData.mbl);
                    if (wishlistData.info.trim().length) {
                        $('.wishlistmenu-container').html(wishlistData.info);
                        $('.shopby-list').removeClass('hidden');
                        $('.shopby-list > a').addClass('loaded');
                        $('#shop-by-list-toggle').removeClass('disabled');
                    }
                    $('#mobile-menu').mmenu({
                        extensions: ['effect-slide-menu', 'pageshadow'],
                        searchfield: false,
                        counters: false,
                        navbar: {
                            title: ['']
                        },
                        offCanvas: {
                            position: "left"
                        },
                        navbars: [{
                                position: 'top',
                                content: [
                                    'prev',
                                    'title',
                                    'close'
                                ]
                            }]
                    }, {
                        offCanvas: {
                            pageSelector: ".page-wrapper",
                            wrapPageIfNeeded: false
                        }
                    });
                    menuActive();
                    menuHoverClick();
                    addNewGroupList();
                    $('.mob-branch,.change-add').on('click', function () {
                        $("#mobile-menu").data("mmenu").close();
                    });
                }
                if (brandMenu && params.isDisplayBrandMenu != '0' && brandMenu.info) {
                    renderBrandMenu(brandMenu.info);
                } else if(params.isDisplayBrandMenu == '0') {
                    disableBrandMenu();
                }
            }

            function renderBrandMenu(menuData)
            {
                var brandMenuContainer = $('.brandmenu-container');
                brandMenuContainer.html(menuData);
                $('.shopby-brand > a').addClass('loaded');
                brandMenuContainer.removeClass('hidden');
            }

            function disableBrandMenu()
            {
                $('.shopby-brand').addClass('hidden');
            }

            function shopByBrand(data)
            {
                if (params.isDisplayBrandMenu != '0' && typeof data.brandMenu !== 'undefined' && data.brandMenu.length != 0) {
                    var brandMenuData = {
                        info: data.brandMenu,
                        timestamp: Date.now()
                    }
                    window.setLocalStorage('brandMenu', brandMenuData, true);
                    renderBrandMenu(data.brandMenu);
                } else {
                    disableBrandMenu();
                }
            }

            function fetchShopByCategoryData()
            {
                var configPath = "navcustommenu/index/menuajax";
                $.getJSON(params.baseurl + configPath, function(data) {
                    menuData = {
                        desk: data.deskmenu,
                        mbl: data.mblmenu,
                        timestamp: Date.now()
                    };
                    window.setLocalStorage('menuData', menuData, true);
                    shopByBrand(data);
                    initMenu();
                });
            }

            function fetchShopByListData()
            {
                $.ajax({
                    url: params.baseurl + params.shopByListURL,
                    success: function(data) {
                        wishlistData = {
                            info: data,
                            timestamp: Date.now()
                        };
                        window.setLocalStorage('wishlistData', wishlistData, true);
                        initMenu();
                    }
                });
            }

            function loadMenuFromCache() {
                var load = false;
                if(params.isDisplayBrandMenu != '0' && brandMenu && brandMenu.info) {
                    load = true;
                } else if(params.isDisplayBrandMenu == '0') {
                    load = true;
                }
                return load;
            }

            if (menuData && wishlistData && loadMenuFromCache()) {
                initMenu();
            } else {
                fetchShopByCategoryData();
                fetchShopByListData();
            }

            function menuActive() {
                $('body .category-container').on('click', function (e) {
                    e.stopPropagation();
                });

                $('.category-container ul.submenu li').on('mouseenter',function () {
                    $(this).closest('.submenu').parent().closest('.submenu').addClass('hovered');
                }).on('mouseleave', function () {
                    $(this).closest('.submenu').parent().closest('.submenu').removeClass('hovered');
                });

                var timeout;
                $('.category-container ul.level0 li').on('mouseenter',function (e) {
                    var that = $(this);
                    e.preventDefault();
                    timeout = setTimeout(function () {
                        $('.category-container').find('.actived:not(:hover)').removeClass('actived');
                        that.addClass('actived');
                    }, 200);
                }).on('mouseleave',function (e) {
                    e.preventDefault();
                    clearTimeout(timeout);
                });
                $('.category-container').on('mouseleave', function () {
                    $('.category-container').find('.actived').removeClass('actived');
                });
            }

            function addNewGroupList() {
                $('.addgroup-newlist').on('click', function () {
                    var listtype = $(this).data('listtype');
                    var parentid = $(this).data('parentid');
                    var category = $(this).closest('.add-wlist').find('.addgroup-input').val();
                    var myurl = params.baseurl + "advancewishlist/index/add";
                    $.ajax({
                        url: myurl,
                        type: 'POST',
                        data: {'list_type': listtype, 'parent_id': parentid, 'category_name': category},
                        success: function (data) {
                            window.location.reload();
                            $(this).prepend(data);
                        }
                    });
                });
            }

            function menuHoverClick() {
                $('.shopby-list .submenu').each(function () {
                    if ($(this).prev('.fa-angle-right').length < 1) {
                        $(this).before('<i class="fa fa-angle-right" ></i>');
                    }
                });
            }

        };
        fn();
    };
});

