define('Usesi_Wishlists/js/view/shoppinglist',[
    'uiComponent',
    'ko',
    'Magento_Customer/js/customer-data'
], function (Component, ko, customerData) {
    'use strict';

    return Component.extend({
        baseList: ko.observableArray([]),
        isEnabled: ko.observable(true),
        selected: ko.observable(''),
        initialize: function () {
            this._super();
            var self = this;
            var sections = ['wishlists'];
            customerData.invalidate(sections);
            customerData.reload(sections, true);
            self.baseList = customerData.get(sections);
            self.radiobtnclick = function (item, event) {
                if (event.target.id === 'new') {
                    self.isEnabled(true);
                } else {
                    self.isEnabled(false);
                    jQuery('.validation-msg').hide();
                    $('#shoppinglistModal #mshoppinglist_new').attr('disabled', true);
                    self.selected(event.target.value);
                }
                return true;
            };
            self.getShoppingList = function () {
                var self = this;
                return self.baseList().shoppinglist;
            }
        }
    });
});
